.App {
  height: 100vh;
  width: 100%;
}

.markable-item {
  cursor: pointer;
  border-radius: 0.28571429rem;
  margin-bottom: 0.285714285em;
  padding: 0.21428571em 0 0.21428571em 0.285714285em !important;
}

.markable-item:last-child {
  margin-bottom: 0;
}

.markable-item > i {
  padding-top: 0.142857145em !important;
}

.recipe-image {
  height: 45px !important;
  min-width: 80px;
  background: repeating-linear-gradient(
    45deg,
    rgba(186, 186, 186, 0.2),
    rgba(186, 186, 186, 0.2) 10px,
    rgba(219, 219, 219, 0.2) 10px,
    rgba(219, 219, 219, 0.2) 20px
  );
}
.ingredient-row > div:first-of-type {
  padding-right: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.ingredient-row > div:first-of-type .button {
  margin-right: 1rem !important;
}

.ingredient-row > div:first-of-type input {
  width: inherit !important;
}
